import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHeader from "../components/molecules/InnerHeader"
import ContentSectionWithImage from "../components/common/ContentSectionWithImage"
import ServicesSection from "../components/common/ServicesSection"
import { contactSection, servicesSection } from "../content/Common"
import ContactSection from "../components/common/ContactSection"
import WhatWeDoSection from "../components/pages/Services/WhatWeDoSection"
import { aboutusSection, servicesPage, whatWeDoSection, whyUseThisServicesSection } from "../content/ServicesPage"

const Services = () => (
  <Layout>
    <Seo title="Services" />

    <InnerHeader
      title={servicesPage.title}
      googleReviewText={servicesPage.googleReviewText}
      description={servicesPage.description}
    />

    <ContentSectionWithImage data={aboutusSection} />

    <ServicesSection data={servicesSection} />

    <WhatWeDoSection data={whatWeDoSection} />

    <ContentSectionWithImage data={whyUseThisServicesSection} componentEC={`!flex-col-reverse md:!flex-row-reverse`} />

    <ContactSection data={contactSection} sectionTitleAlignment={`center`} />

  </Layout>
)

export default Services
