import React from "react"
import SectionTitle from "../atoms/SectionTitle"
import BrandParagraph from "../atoms/BrandParagraph"
import Button from "../atoms/Button"
import { Link } from "gatsby"
import { siteDetails } from "../../content/Global"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import ContactForm from "../molecules/ContactForm"
import ImageRenderer from "../atoms/ImageRenderer"

const ContactSection = ({ data, sectionTitleAlignment }) => {
    let align = '';
    if(sectionTitleAlignment === 'center'){
        align ='text-center justify-center';
    }else if(sectionTitleAlignment === 'right'){
        align ='text-right justify-end';
    }
  return (
    <div className="relative">
      <div className="absolute left-0 bottom-[-80px] max-w-[350px] hidden md:block">
        <ImageRenderer
          img={`contact-section-arrow-image`}
          alt={`contact-section-arrow-image`}
        />
      </div>
      <div className="cc-section--horizontal-space cc-section--vertical-space relative md:!pb-[120px] mb-[120px]">
        <SectionTitle
          title={data.sectionTitle}
          titleEC={align}
          componentEC={align}
        />
        <div className="flex flex-col md:flex-row items-center justify-between gap-[70px] md:gap-[45px] mt-16">
          <div className="flex-[3]">
            <SectionTitle title={data.mainTitle} isTopImage={false} />
            <BrandParagraph text={data.decription} textEC={`mt-4`} />
            <div className="mt-8 mb-4">
              <Button
                to={siteDetails.phoneLink}
                href={siteDetails.phoneLink}
                text={data.btnText}
                ec={`primary-btn block max-w-[180px] mx-auto md:mx-0`}
                icon={faPhone}
              />
            </div>
            <div className="max-w-[100%] md:max-w-[180px] mb-2 text-center text-[18px] font-[600] text-[#000000]">
              OR
            </div>
            <Link
              to={`mailto:` + siteDetails.email}
              href={`mailto:` + siteDetails.email}
            >
              <div className="flex items-center justify-center md:justify-start gap-[10px]">
                <FontAwesomeIcon icon={faEnvelope} className="text-[#25282C]" />
                <span className="text-[#25282C] font-[700]">
                  Email Us{" "}
                  <span className="text-secondary">{siteDetails.email}</span>
                </span>
              </div>
            </Link>
          </div>
          <div className="flex-[4] w-full pr-[20px]">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
