import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import SectionTitle from "../atoms/SectionTitle"
import BrandParagraph from "../atoms/BrandParagraph"
import IconBox from "../atoms/IconBox"
import Button from "../atoms/Button"
import IconListItem from "../atoms/IconListItem"
import Faqs from "../molecules/Faqs"

const ContentSectionWithImage = ({ data, componentEC }) => {
  return (
    <div
      className={`cc-section--horizontal-space cc-section--vertical-space flex flex-col-reverse md:flex-row gap-[60px] items-center ${componentEC}`}
    >
      <div className="flex-[1]">
        {data.sectionMainImage && (
          <ImageRenderer
            img={data.sectionMainImage.path}
            alt={data.sectionMainImage.alt}
          />
        )}
      </div>
      <div className="flex-[1]">
        <SectionTitle
          title={data.sectionTitle}
          titleEC={`mb-3 md:!text-[36px]`}
        />
        {data.description && <BrandParagraph text={data.description} />}
        {data.iconBoxes && (
          <div className="flex flex-col gap-[30px] md:gap-[12px] mt-8 md:mt-5">
            {data.iconBoxes.map((item, index) => (
              <IconBox
                key={"abouticonbox_key" + index}
                componentEC={`flex flex-col md:flex-row gap-[20px] md:items-center`}
                image={item.iconImage}
                imageComponentEC={`w-[52px] h-[52px] min-w-[52px] min-h-[52px]`}
                imageEC={`max-w-[24px]`}
                title={item.title}
                titleEC={`!mb-0`}
                description={item.description}
              />
            ))}
          </div>
        )}
        {data.list && data.list.length > 0 && (
          <ul className="flex flex-col gap-5 mt-8">
            {data.list.map((item, index) => (
              <IconListItem key={index} text={item} />
            ))}
          </ul>
        )}
        {data.faqs && <Faqs faqs={data.faqs} />}
        {data.extraDescription && (
          <BrandParagraph
            text={data.extraDescription}
            textEC={`mt-5 ${data.extraDescriptionEC}`}
          />
        )}
        {data?.btnText && (
          <div className="mt-10 mb-10">
            <Button
              to={data.btnLink}
              href={data.btnLink}
              text={data.btnText}
              ec={`primary-btn`}
            />
          </div>
        )}
        {data?.extraInfo && <BrandParagraph text={data.extraInfo} />}
      </div>
    </div>
  )
}

export default ContentSectionWithImage
